<template>
    <div >
      <h1>{{msg}}</h1>
      <button
        class="btn btn-primary btn-margin"
        @click="privateMessageAPI()">
        Call Private API2
      </button>
      <br/>
      <button
        class="btn btn-primary btn-margin"
        @click="doEncryptDecrypt()">
        Encrypt/Decrypt con MetaMask
      </button>
      <LayoutPage></LayoutPage>
    </div>
</template>
<script>
  import LayoutPage from '../layout/LayoutPage.vue'
  import { Auth,API } from 'aws-amplify'
  const apiName = 'ManiacMansionAPI'
  const path = '/'
  const ethUtil = require('ethereumjs-util') // For Encrypt Message
  const sigUtil = require('eth-sig-util') // For Encrypt Message
  export default {
    name: 'Dashboard2',
      components: {
        LayoutPage
      },
    data () {
      return {
        msg: 'Welcome to Dashboard Appp'
      }
    },
    methods: {
      privateMessageAPI () {
        Auth.currentAuthenticatedUser().then(user => { 
          // console.log(user.signInUserSession.idToken.jwtToken)
          const myInit = { 
              headers: {Authorization: user.signInUserSession.idToken.jwtToken }
          }
          API
          .get(apiName, path, myInit)
          .then(response => {
              // console.log('premio')
          });        
        })
      },
    },
} 

Auth.currentAuthenticatedUser().then(user => { 
// console.log(user.signInUserSession.idToken.jwtToken)
})
</script>
